@import "styles/variables";

@mixin footer-link($decoration){
  display: block;
  padding: 5px 0;
  color: inherit;
  transition: all 0.2s;
  text-decoration:none;
  font-weight: 300;
  &:hover{
    text-decoration: underline;
    font-weight: bold;
  }
}

#footer{
  .mainBar{
    padding: 30px 0;
    background-color: $black-bg-color;
    color: $text-white-color;
    text-align: center;
    .row{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    h2{
      font-size: 14px;
      margin: 0 0 20px;
      padding: 0;
      color: inherit;
    }
    address{
      font-size: 0.9em;
      font-style: normal;
      margin-bottom: 30px;
      color: inherit;
      div{
        padding: 5px 0;
        font-weight: 300;
        color: inherit;
      }
      a{
        @include footer-link(none);
        color: inherit;
      }
    }
    .links{
      ul{
        font-size: 0.9em;
        display: block;
        padding: 0;
        margin: 0;
        list-style: none;
        li{
          a{
            @include footer-link(underline);
          }
        }
      }
    }
    .socialBar{
      padding: 15px 0;
      display: flex;
      justify-content: center;
      a{
        margin-right: 12px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: white;
        transition: all 0.2s;
        padding: 0;
        &:last-child{
          margin-right: 0;
        }
        &:hover{
          background-color: #FBD136;
        }
      }
    }
    @media (min-width: 600px){
      text-align: left;
      .row{
        flex-direction: row;
      }
      address{
        margin-bottom: 0;
      }
      .links{
        width: 50%;
      }
      .socialBar {
       justify-content: flex-start;
      }
    }
    @media (min-width: 900px){
      .links{
        width: 40%;
      }
    }
  }

  .supporters{
    background-color: #F3F4F3;
    padding: 25px 0;
    .content{
      text-align: center;
      .logo{
        display: inline-block;
        width: 121px;
        margin-bottom: 20px;
      }
      .support{
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 800px) {
          flex-direction: column;
        }
        span{
          font-weight: bold;
          max-width: 250px;
          font-size: 14px;
          @media (max-width: 800px){
            max-width: unset;
          }
        }
        & > * {
          display: block;
          margin: 30px 0;
          @media (max-width: 800px){
            margin: 0;
          }
        }
      }
      .copyright{
        display: inline-block;
        min-width: 121px;
        text-align: right;
        font-size: 11px;
        line-height: 14px;
        margin-top: 20px;
      }
      @media (min-width: 800px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .logo{
          margin-bottom: 0px;
        }
        .copyright{
          margin-top: 0;
        }
      }
    }
  }


  .creator{
    background-color: #eee;
    color: black;
    text-align: center;
    padding: 10px;
    font-size: 0.8em;
    a{
      color: black;
      font-weight: bold;
    }
  }
  @media print{
    display: none;
  }
}

.newsletterFormHolder {
  padding-top: 30px;
  padding-bottom: 0;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 37%, rgba(244,244,244,1) 37%, rgba(244,244,244,1) 100%);

  @media screen and (min-width: $lg) {
    padding-top: 100px;
  }

  .newsletterFormWrapper {
    background-color: $color-priroda-bg;
    padding: 40px 10px;
    color: $text-color;

    @media screen and (min-width: $xs) {
      padding: 40px 20px;
    }

    @media screen and (min-width: $lg) {
      padding: 80px 50px;
    }

    h5 {
      font-size: 28px;
      line-height: 34px;
      font-weight: bold;
      color: inherit;
      margin-bottom: 16px;

      @media screen and (min-width: $lg) {
        font-size: 38px;
        line-height: 48px;
      }
    }

    p {
      font-size: 14px;
      line-height: 19px;
      color:inherit;
      margin-bottom: 16px;

      @media screen and (min-width: $md) {
        margin-bottom: 0;
      }

      @media screen and (min-width: $lg) {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}
