@import "styles/variables";

.newsletter {
    :global(.ant-row) {
        margin-bottom: 0px;
    }
  .agreementCheckboxWrapper {
    label {
      color: $text-color;
    }
  }
}


.submitWrapper {
  margin-top: 0;

  @media screen and (min-width: $md) {
    margin-top: 10px;
  }
}
