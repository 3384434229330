@import "styles/variables";
.cart{
    display: flex;
    position: relative;
    align-items: flex-end;
    .badge{
        // width: 17px;
        // height: 17px;
        display: flex;
        border-radius: 50%;
        background-color: #FBD136;
        // color: white;
        // EDITED to be more visible
        width: 19px;
        height: 19px;
        color: black;
        border: 2px solid black;
        //
        font-weight: bold;
        justify-content: center;
        align-items: center;
        font-size: 0.7em;
        margin-right: -5px;
    }
}
.popup {
    position: absolute;
    display: flex;
    top: calc(100% + 5px);
    left: 0;
    min-width: 150px;
    min-height: 50px;
    width: fit-content;
    padding: 20px;
    background-color: #FBD136;
    color: black;
    text-align: left;
    align-items: center;
    justify-content: center;
    border-radius: calc(#{$general-radius} * 3);
    &::before {
        content: "";
        width: 0;
        height: 0;
        border-width: 0 7.5px 10px 7.5px;
        border-color: transparent transparent #FBD136 transparent;
        border-style: solid;
        position: absolute;
        top: -7px;
        left:5px
    }
}
