.authNotLogged{
    text-align: right;
    display: none;
    @media (min-width: 500px) {
        display: block;
    }
    @media print{   
        display: none;
    }
}

.authLogged{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .wishlistButton{
        .wishlistCount{
            margin-left: 5px;
        }
    }
    .user{
        margin-left: auto;
        display: none;
        .loggedUser{
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.1em;
            cursor: pointer;
            .credits{
                display: inline-block;
                background-color: #FBD136;
                color: black;
                margin-right: 10px;
                padding: 0 10px;
                border-radius: 10px;
                font-weight: 300;
            }
        }
        @media (min-width: 600px) {
            display: block;
        }
    }
}
