@import "styles/variables";

html{
  scroll-behavior: smooth;
}

h2.title {
  color: $text-color;
  font-size: 22px;
  font-weight: 700;
  display: block;
  width: 100%;
}

body {
  padding-top: 45px;
  font-family: 'Manrope', sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 10px;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  color: #43525a;
  transition: all 0.3s;
  font-size: 0.9em;
  border: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.35),
      -5px -5px 5px rgba(255, 255, 255, 0.25);
    color: black;
    text-decoration: underline;
  }
  &.btn-white {
    background-color: white;
  }
  &.btn-yellow {
    background-color: #fbd136;
    color: black;
  }
  &.btn-dark {
    background-color: #43525a;
    color: white;
  }

  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
    box-shadow: none;
    color: inherit !important;

    &:hover {
      box-shadow: none;
      opacity: 0.8 !important;
      text-decoration: none;
      color: inherit !important;
    }
  }

  svg {
    margin-right: 10px;
  }
}

.ant-input-affix-wrapper,
.ant-input {
  border-radius: 24px;
  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  &:focus {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.35),
      -5px -5px 5px rgba(255, 255, 255, 0.25);
  }
  .ant-input {
    border-radius: 0;
    &:hover {
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.phone-input-component {
  .ant-form-item-control-input-content {
    border-radius: 24px !important;
    border-color: #ffffff;
    &:hover {
      box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
    }
  }
  .ant-form-item-has-error {
    .ant-form-item-control-input-content {
      border-color: #ff4d4f !important;
    }

    label {
      padding-left: 40px !important;
      &.label-float-custom {
        padding-left: 5px !important;
      }
    }
  }
  .PhoneInput {
    padding: 10px 11px !important;
  }
}

@import "./category-filter.scss";

.ant-radio {
  &-inner {
    border-radius: 4px;
  }

  &-checked {
    .ant-radio-inner {
      &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
      }
    }
  }
}

.btn-podujatia {
  background-color: $color-podujatia-bg;
  color: white;
  font-size: 12px;
  line-height: 20px;
  padding: 17px;
  &:hover {
    color: white;
    background-color: $color-podujatia-bg-hover;
  }

  &[disabled] {
    background-color: lighten($color-podujatia-bg, 20);
    color: white;
    &:hover {
      background-color: lighten($color-podujatia-bg, 20);
      color: white;
    }
  }

  &.ant-btn-loading {
    color: $color-podujatia-bg;
  }

  &--lg {
    font-size: 18px;
    padding: 22px 27px;
    line-height: 0;
  }
}

.checkbox-podujatia {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $color-podujatia-bg;
      border-color: $color-podujatia-bg;
    }

    &:after {
      border-color: $color-podujatia-bg;
    }
  }
}

.bordered-input-form {
  .ant-input {
    border-color: #d9d9d9;

    &:focus {
      border-color: #ffe261;
    }
  }
}
