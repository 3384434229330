@import "styles/variables";

.menuItem {
  position: relative;

  &:hover {
    font-weight: bold;

    .submenu {
      display: block;
    }
  }

  .submenu {
    position: relative;

    padding: 10px 15px;
    border-radius: calc(#{$general-radius} * 3);
    background: rgba(255, 255, 255, 0.95);
    min-width: 200px;
    font-weight: normal;
    color: $text-color;

    @media (min-width: $mobile-menu-breakpoint) {
      display: none;
      position: absolute;
      top: 100%;
      left: 10px;
    }

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-width: 0 7.5px 10px 7.5px;
      border-color: transparent transparent #ffffff transparent;
      border-style: solid;
      position: absolute;
      top: -7px;
    }

    li {
      display: block !important;

      a {
        padding: 5px 0 !important;
        font-size: 12px !important;

        &:hover {
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
  }
}

.languageSwitch {
  @media (max-width: $mobile-menu-breakpoint) {
    svg {
      display: none !important;
    }
  }
}
