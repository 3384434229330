@import "styles/variables";

.wishlist {
  display: flex;
  align-items: flex-end;
  padding: 0 5px;
  position: relative;
  svg {
    color :$color-podujatia-bg !important;
    &:hover {
      color: $color-podujatia-bg-hover !important;
    }
  }

  @media (min-width: $lg) {
    margin-right: 15px;
    padding: 0;
  }

  .badge {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    border-radius: 50%;
    background-color: #fbd136;
    // color: white;
    // EDITED to be more visible
    width: 19px;
    height: 19px;
    color: black;
    border: 2px solid black;
    //
    font-weight: bold;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;

    @media (min-width: $md) {
      bottom: 0;
      left: -5px;
    }
  }
}
