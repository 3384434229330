@import "styles/variables";

.srandonHeader{
  background: $color-yellow;
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: $black-bg-color;
  color: $text-white-color;
  font-size: 12px;

  @media (max-width: $mobile-menu-breakpoint) {
    background: linear-gradient(to bottom, $color-white, $color-white, $color-white, $color-white, transparent);
    color: $text-color
  }

  .logoWrapper {
    display: none;
    @media (max-width: $mobile-menu-breakpoint) {
      display: block
    };
  }

  .separator {
    height: 1px;
    border-bottom: 1px solid #43525a;
    width: 100%;
    z-index: 2;
    position: relative;
  }

  .topBar {
    padding-top: 13px;
    padding-bottom: 13px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    position: relative;

    .actionButtons {
      display: flex;;
      a {
        color: inherit;
        display: flex;
        gap: 5px;
        align-items: center;
        margin: 0 10px;
        &:hover {
          text-decoration: none;
          font-weight: bold;

        svg {
          color: $color-pamiatky-bg-hover;
        }
        }
        svg {
          width: 10px;
          height: 10px;
          color: $color-pamiatky-bg;
        }
      }
      .linksDivider {
        align-items: center;
        margin: 0 10px;
      }
      @media (max-width: $mobile-menu-breakpoint) {
        display: none;
      }
    }

    .authButton {
      display: none;
      @media (min-width: $lg) {
        display: block;
        margin-left: 40px;
        :global(.btn) {
          box-shadow: none;
          padding: 10px 30px;
        }
      }
    }

    .menuWrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      display: none;
      z-index: 4;
      @media screen and (max-width: $mobile-menu-breakpoint) {
        padding-top: 70px;
        padding-bottom: 20px;
      }
      &.open {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: auto;
      }
      ul.menu {
        position: relative;
        display: block;

        text-align: left;
        padding: 0;

        @media (min-width: $mobile-menu-breakpoint) {
          margin: 0;
        }

        li {
          display: block;
          a {
            display: block;
            color: inherit;
            padding: 10px;
            font-size: 1em;

            @media (min-width: $md) {
              padding: 20px 10px;
            }

            &:hover {
              text-decoration: none;
            }

            &.firstLevel {
              &:hover {
                font-weight: bold;
              }
            }
          }
        }
        .actionButtons {
          display: block;
          a {
            display: flex;
            margin: 0;
          }
          @media (min-width: $mobile-menu-breakpoint) {
            display: none;
          }
        }
      }
      .logo {
        display: block;
        top: 10px;
        left: 10px;
        position: absolute;
        picture,
        img {
          width: 106px;
        }
      }
      .closeButton {
        position: absolute;
        top: 15px;
        right: 10px;
      }
      @media (min-width: $mobile-menu-breakpoint) {
        position: relative;
        top: auto;
        width: auto;
        background-color: transparent;
        box-shadow: none;
        display: block;
        &.open {
          display: block;
        }
        ul.menu {
          display: inline-block;
          margin: 0;
          li {
            display: inline-block;
            a {
              padding: 0 10px;
            }
          }
        }
        .logo,
        .closeButton {
          display: none;
        }
      }
      @media print {
        display: none;
      }
    }

    .iconsWrapper {
      text-align: right;
      display: flex;
      align-items: center;
      .mobileMenu {
        padding: 0 5px;
        @media (min-width: $mobile-menu-breakpoint) {
          display: none;
        }
      }
      @media print {
        display: none;
      }
    }
  }

  .loginBar {
    position: relative;
    margin-top: 20px;
    z-index: 2;
    @media print {
      display: none;
    }
  }
}
